import Vue from 'vue'
import App from './App.vue'
import { Carousel3d, Slide } from 'vue-carousel-3d'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueRouter from 'vue-router'
import router from './routes'

Vue.use(VueRouter);
Vue.config.productionTip = false
Vue.component('carousel-3d',Carousel3d);
Vue.component('slide', Slide);
Vue.component('VueSlickCarousel',VueSlickCarousel );

const route = new VueRouter({
  routes: router,
  mode: 'history'
});

new Vue({
  render: h => h(App),
  router: route,

  mounted(){
    // Bulma Carousel CDN
    let bulmaCarousel = document.createElement('script')
    bulmaCarousel.setAttribute('src', 'https://cdn.jsdelivr.net/npm/bulma-carousel@4.0.24/dist/js/bulma-carousel.min.js')
    document.head.appendChild(bulmaCarousel)

    // BULMA CDN
    let bulmaCDN = document.createElement('link')
    bulmaCDN.setAttribute('rel', 'stylesheet')
    bulmaCDN.setAttribute('href', 'https://cdn.jsdelivr.net/npm/bulma@0.9.3/css/bulma.min.css')
    document.head.appendChild(bulmaCDN)
  }
}).$mount('#app')