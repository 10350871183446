<template>
    <div id="home" class = "home" ref="home" v-cloak>
        <div class="modal loading-modal" :class="{'active': loading, 'is-active': loading}">
            <div class="modal-background" style="background: black;"></div>
            <div class="modal-content">
                <center>
                    <p class="image is-128x128">
                    <img src="cat.gif" alt="">
                    </p>
                </center>
            </div>
        </div>         
        <div class="temp" :class="{'is-active': sections}">  
            <nav id="menu" class="navbar nav-menu" role="navigation" aria-label="main navigation">
                <div id="mv_left" class="navbar-brand text-header">
                    <a class="navbar-item icon-text" @click="goto('home')">
                        <span class="icon is-128x128" style="transform: scale(4); margin-left: 10px; margin-right: 35px; margin-top: 5px ">
                            <img src="roki.png" alt="">
                        </span>
                        <span class="nav-title primary-color">Revolution Of Kitten, Inc.</span>
                    </a>
                    <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample"  @click="showNav = !showNav" :class="{ 'is-active': showNav }">
                        <span aria-hidden="true" class = "nav-hamburger"></span>
                        <span aria-hidden="true" class = "nav-hamburger"></span>
                        <span aria-hidden="true" class = "nav-hamburger"></span>
                    </a>
                </div>
                <div class = "nav-line" />
                <div class="navbar-menu"  :class="{ 'is-active': showNav }">
                    <div class="navbar-item navbar-end white">
                        <a class="navbar-item button-border button is-rounded round-border white"  @click="goto('home')">
                            Home
                        </a>
                        <a class="navbar-item button-border button is-rounded round-border white"  @click="goto('services')">
                            Services
                        </a>
                        <a class="navbar-item button-border button is-rounded round-border white"  @click="goto('company')">
                            Our Company
                        </a>
                        <a class="navbar-item button-border button is-rounded round-border white"  @click="goto('team')">
                            The Team
                        </a>
                        <!-- --------------------Currently Commented Out until new social links are given------------------------------------- -->
                        <!-- <a href="https://twitter.com/RevKitten" target = '_blank' class="button button-nav round-border fa fa-twitter button-border" style="transform: scale(1.1)"></a>
                        <a href="https://www.facebook.com/RevolutionofKitten" target = '_blank' class="button button-nav round-border fa fa-facebook button-border" style="transform: scale(1.1)"></a>
                        <a href="https://www.linkedin.com/company/revolution-of-kitten-inc." target = '_blank' class=" button button-nav round-border fa fa-linkedin button-border" style="transform: scale(1.1)"></a> -->
                        
                        <a class="navbar-item button is-rounded primary-background" @click="goto('career')">
                            Contact Us
                        </a>
                        <div class = "nav-line"  v-show="showNav" />
                    </div>
                </div>
            </nav>
            <section class="section" style="padding: 0">
                <div class="front-page">
                    <div class="punch-line">
                        <div class="column">
                            <div style="padding-left: 10vw; padding-right: 10vw;">
                                <h2 class="white company-name">"Bringing <span class="outline">Innovations</span> to Lives"</h2>
                            </div>
                        </div>
                    </div>

                    <div class="carousel-one-container">
                        <VueSlickCarousel @afterChange="onCarouselChange" @beforeChange = "show = false" :pauseOnHover="false" :arrows="false" :dots="false" autoplay=true class="carousel-one">
                            <div v-for="(slide, i) in start_images" :index="i" :key="i" class = "justify-content-center">
                                <figure class="carousel-one-image">
                                    <img :src="slide.src"  class="carousel-image"/>
                                </figure>
                            </div>
                        </VueSlickCarousel>
                        <transition name="fade">
                            <div v-if="show" class = "carousel-one-overlay">
                                <figure class="carousel-one-image-overlay">
                                    <img :src="carousel_overlay[overlay_index].src"  class="carousel-image"/>
                                </figure>
                            </div>
                        </transition>
                    </div>
                </div>

                <!-- SERVICES -->
                <div class="block" ref="services">
                    <div class="title primary-color">
                        <a class="title service-header primary-color">
                            SERVICES
                        </a>
                    </div>
                    <div v-for="(slide, i) in services" :index="i" :key="i" class="card white noShadow">
                        <div class="card-content" v-if="i%2 == 0">
                            <div class="content white columns">
                                <div class="column service-info service-details">
                                    <div class="oShadow white">
                                        <h1 v-html="slide.title" class=" service-title white"></h1>
                                    </div>
                                    <p v-html="slide.description1" class="text-body"></p>
                                    <p v-html="slide.description2" class="text-body"></p>
                                </div>
                                <div class="column service-image">
                                    <div class="column">
                                        <figure class="image is-5by3">
                                            <img :src="slide.src" class = "service-image"/>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div class="card-content" v-else>
                            <div class="content white columns">
                                <div class="column service-image">
                                    <div class="column">
                                        <figure class="image is-5by3">
                                            <img :src="slide.src" class = "service-image"/>
                                        </figure>
                                    </div>
                                </div>
                                <div class="column service-info service-details">
                                    <div class="oShadow white">
                                        <h1 v-html="slide.title" class=" service-title white"></h1>
                                    </div>
                                    <p v-html="slide.description1" class="text-body"></p>
                                    <p v-html="slide.description2" class="text-body"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- OUR COMPANY -->
                <div class="block" ref="company">
                    <div class="columns">
                        <div class="screen round">
                            <div class = "card noShadow">
                                <header class="card-header noShadow">
                                    <p class="card-header-title is-centered">
                                        <a class="title primary-color">
                                            OUR COMPANY
                                        </a>
                                    </p>
                                </header>
                                <div class="card-content">
                                    <div class="content">
                                        <div class="columns">
                                            <div class="column white ">
                                                <center>
                                                    <figure>
                                                        <img class="company-image" src="roki.png" alt="" >
                                                    </figure>
                                                </center>
                                                <div class="company-details text-body">
                                                <p><span class="primary-color">Revolution of Kitten</span> is a software development company located in Cebu City Philippines. We specialize in high technology cutting edge solutions for a global customer base.
                                                    Our specialties include applications for Robots, Artificial Intelligence, Augmented/Mixed reality, Internet of Things, and more. New emerging technologies are our forte.</p>
                                                
                                                <p>We began in 2016 as an offshoring venture to maximize the low cost of business in the Philippines while utilizing the benefits of its young, well educated, and highly motivated workforce. </p>
                                                <p>Our goal is to grow into a global leader in providing applications for cutting edge new technology that is ready to change the world. 
                                                    Since 2016 we have experienced exponential growth and partnered with major global technology corporations. We are excited about the years to come and the many opportunities that await us.</p>
                                                <p>Although we have grown quickly we are committed to maintaining a family work culture that emphasizes teamwork and the personal and professional growth of our staff. 
                                                    We have seen the fruits of this approach as we are able to maintain low cost, quick turnaround, and high quality on all our projects.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="column" >
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>

                <!-- THE TEAM -->
                <div class="block" ref="team">
                    <div class="columns">
                        <div class="screen" style="background:  transparent;">
                            <div class="team-card secondary-background round">
                                <header class="card-header noShadow">
                                    <div class="card-header-title is-centered primary-color">
                                        <a class="title team-title primary-color">
                                            THE TEAM
                                        </a>
                                    </div>
                                </header>
                                <div class="card-content">
                                    <div v-if="windowWidth <= 1400" class="content team-carousel" style="">
                                        <VueSlickCarousel :arrows="true" :dots="true" :slidesToShow='1' :rows='1' :autoplay='true'>
                                            <div v-for="(t, i) in team" :index="i" :key="i" class="white">
                                                <figure class="team-image">
                                                    <img :src="t.src" class="has-padding"/>
                                                </figure>
                                            </div>
                                        </VueSlickCarousel>
                                    </div>
                                    <div v-else class="content team-carousel" style="">
                                        <VueSlickCarousel :arrows="true" :dots="true" :slidesToShow='2' :rows='1' :autoplay='true'>
                                            <div v-for="(t, i) in team" :index="i" :key="i" class="white">
                                                <figure class="team-image">
                                                    <img :src="t.src" class="has-padding"/>
                                                </figure>
                                            </div>
                                        </VueSlickCarousel>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- CAREER OPPORTUNITIES -->
                    <div class="block" ref="career">
                        <div class="column">
                            <div class="tertiary-background round">
                                <div class = "card noShadow">
                                    <header class="card-header noShadow">
                                        <p class="card-header-title is-centered primary-color">
                                            <a class="title primary-color">
                                                CAREER OPPORTUNITIES
                                            </a>
                                        </p>
                                    </header>
                                    <div class="card-content">
                                        <div class="content white text-body" style="text-align: center;">
                                            <p>We are hiring now!</p>
                                            <p>Are you an I.T. professional or recent graduate looking for challenging and exciting work? If so, send us your CV for consideration.
                                                We are looking for driven, self motivated people who want to work in new, cutting edge software development.
                                                If you have experience with or are interested in Augmented/Mixed Reality, Artificial Intelligence, Internet of Things, Robots, and other 
                                                new technologies then Revolution of Kitten is a great place for you.</p>
                                            <p>We work together as a team, helping each other grow and learn and accomplish amazing things. We foster a family atmosphere with monthly outings and 
                                                events so that we don't just work hard but have fun and enjoy ourselves as well. It is our philosophy to invest in the potential of our staff by 
                                                having them learn new things and continually grow professionally. We want working at Revolution of Kitten to not just be a job, but a career 
                                                opportunity to become the best I.T. professional you can possibly be.</p>
                                            <!-- <p>Contact us today.</p> -->
                                        </div>
                                        <div>
                                            <div class="buttons" style="justify-content: center;">
                                                <a href="mailto:contact@rev-kitten.com?Subject=Get%20in%20Touch" target="_top" class="button is-rounded primary-background">
                                                    Contact us today
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>     
                </div>       
            </section>
            <!-- FOOTER -->
            <div class="block">
                <div class="footer-background">
                    <div class="card-content">
                        <div class="content has-text-left is-small whitesmoke">
                            <p>Revolution of Kitten Inc. <br>
                            Unit 1002-C 10th Floor Keppel Center, Samar Loop Cor. Cardinal Rosales Ave., <br>
                            Cebu Business Park, Cebu City, Philippines 6000 <br>
                            Tel. No. +63.32.233.9711</p>
                            <p>
                                ©2016 Revolution of Kitten. All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'home',
    components: {
    },
    created(){
        setTimeout(() => {
            this.sections = true;
        }, 2500);
        setTimeout(() => {
            this.loading = false;
        }, 2550);
    },
    methods:{
        goto(refName) {
            var element = this.$refs[refName];
            console.log(element);
            var top = element.offsetTop-100;
            window.scroll({
                top: top, 
                left: 0, 
                behavior: 'smooth'
            });
        },
        onCarouselChange(index){
            var i = index;
            this.show = true;
            this.overlay_index=i;
            console.log("Animate + " + i);
            console.log("Shoow is + " + this.show);
        },
    },
    data: function () {
        return {
            services: [
                {
                    title: 'Augmented/Mixed Reality',
                    description1: 'Artificial Reality is the future and we are ready, prepared, and have the experience to bring it to you.',
                    description2:'We are a member of the Microsoft Mixed Reality Partner Program and have created a number of solutions for the Microsoft Hololens device. We have created integrated solutions for Microsoft MR, Google AR, and Apple AR that combine all these services using MS Hololens and Android and Apple AR capable mobile devices. We have extensive experience creating solutions with Matterport SDK (Digital Twin business).',
                    src: 'images/ar01.jpg',
                    src1: [
                        {
                            src: 'images/ar02.jpg',
                        },
                        {
                            src: 'images/ar04.png',
                        },
                    ]
                },
                {
                    title: 'Artificial Intelligence',
                    description1: 'Almost every solution involves AI these days and Revolution of Kitten is experienced and knowledgeable in implementing it.',
                    description2: 'We have in-depth experience in using artificial intelligence and machine learning technology. We are highly capable and have produced solutions with Artificial intelligence development, natural language analysis, video analysis, images evaluation, and big data analysis algorithms.',
                    src: 'images/ai01.jpg',
                    src1: [
                        {
                            src: 'images/r04.JPG'
                        },
                        {
                            src: 'images/ai02.png',
                        }
                    ]
                },
                {
                    title: 'Internet of Things',
                    description1: 'IOT is the future happening today and we are there with award winning application experience.',
                    description2: 'With widespread adoption of 5G networks the possibilities for IOT integration are endless. One of our applications won the IOT award at the prestigious Pepper Challenge. We have extensive experience incorporating IOT into commercial software solutions.',
                    src: 'images/iot02.jpg',
                    src1: [
                        {
                            src: 'images/iot04.png'
                        },
                        {
                            src: 'images/iot01.jpeg',
                        }
                    ]
                },
                {
                    title: 'Robots',
                    description1: 'Working with robots is where we started and it is still a big part of what we can offer.',
                    description2: 'Specializing in human/robot interface we have created test beds and commercial solutions using robots like Pepper, Nao, Tapia, Danny, and many, many more. We are heavily experienced with Client/server ­ application design, development and draft R&D for robot based solutions.',
                    src: 'images/r07.JPG',
                    src1: [
                        {
                            src: 'images/r03.JPG'
                        },
                        {
                            src: 'images/r04.JPG',
                        }
                    ]
                },
            ],
            team: [
                {
                    src: 'staff/1.jpg'
                },
                {
                    src: 'staff/2.jpg'
                },
                {
                    src: 'staff/3.jpg'
                },
                {
                    src: 'staff/4.jpg'
                },
                {
                    src: 'staff/5.jpg'
                },
                {
                    src: 'staff/6.jpg'
                },
                {
                    src: 'staff/7.jpg'
                },
                // {
                //     src: 'staff/9.jpg'
                // },
                {
                    src: 'staff/8.jpg'
                },
                // {
                //     src: 'staff/13.jpg'
                // },
                // {
                //     src: 'staff/14.jpg'
                // },
            ],
            start_images: [
                {
                    src: 'images/Section1-1.png'
                },
                {
                    src: 'images/Section1-2.png'
                },
                {
                    src: 'images/Section1-3.png'
                },
            ],
            carousel_overlay: [
                {
                    src: 'images/Section1-1-overlay.png'
                },
                {
                    src: 'images/Section1-2-overlay.png'
                },
                {
                    src: 'images/Section1-3-overlay.png'
                },
            ],
            overlay_index: 0,
            show: true,
            loading: true,
            sections: false,
            windowWidth: window.innerWidth,
            showNav: false,
    }
  },
}
</script>

<style scoped>
    .button-nav{
        background-color: #fff;
        border-color: #dbdbdb;
        border-width: 1px;
        color: #363636;
        cursor: pointer;
        justify-content: center;
        text-align: center;
        white-space: nowrap;
        padding: 10px;
    }
    .home{
        background-color: rgb(15, 15, 15);
        width: 100vw !important;
        overflow: hidden
    }
/* Default screen size is PC (1025+px) */
    .nav-title{
        font-size: 44px;
        font-family: 'Cera pro','Trebuchet MS',sans-serif;
        margin-top:auto;
        margin-bottom:auto;
    }
    .navbar-burger{
        width:80px;
        height:80px;
        margin-top:auto;
        margin-bottom:auto;
    }
    .nav-hamburger{
        background-color: #ff39c0;
        color: #ff39c0;
        width:24px;
        height:2px;
    }
    .navbar-brand{
        height: 90px;
    }
    .nav-menu{
        position: fixed;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(5px);
        height: 90px;
        font-weight: 600;
    }
    .nav-line{
        position: absolute;
        margin:auto;
        bottom: 0cm;
        height:3px;
        width:100%;
        background-color: #ff39c0;
    }
    .navbar-item{
        align-items: center;
    }
    .scale{
        transform: scale(1.8);
    }
    .company-name{
        font-size: 5vw;
        margin-top: 10px;
        text-align: center;
    }
    .quote{
        font-size: 1.5rem;
        font-family:'Lucida Sans', Arial, sans-serif;
        font-style: italic;position: relative;bottom: 25px;left: 20px;
    }
    .front-page{
        height: 100vh;
        width: 100vw;
        /* font-family:  "Old Standard TT", sans-serif; */
        font-family:  "Gentium Basic", sans-serif;
    }
    .text-header{
        font-family: 'Cera pro','Trebuchet MS',sans-serif;
    }
    .text-body{
        font-family: "Open Sans", sans-serif;
        text-align: justify;
        line-height: 2;
        padding-top: 2vh;
    }
    .punch-line{
        padding-top: 15vh;
    }
    .logo{
        width:5vw;
    }
    .title{
        padding-top: 5vh;
        font-size: 3vw;
        font-family: 'Cera pro','Trebuchet MS',sans-serif;
    }
    .carousel-one-container{
        flex:1;
        position: relative;
        width: 100vw;
        height:60vh;
        justify-content: center;
        align-items: center;
    }
    .carousel-one{
        flex:inherit;
        max-width: 100%;
        height: 100%;
        margin: auto;
        justify-content: center;
        align-items: center;
    }
    .carousel-one-overlay{
        flex:1;
        position: absolute;
        color: rgb(201, 201, 201);
        margin:auto;
        top:0;
        left:0;
        right:0;
        bottom:0;
        align-items: center;
        align-content: center;
        max-width: 100%;
        width: auto;
        height: 100%;
        margin-left: 5%;
        margin-right: 5%;
    }
    .carousel-one-image{
        flex:1;
        height: 60vh;
        margin:auto;
        width: auto;
        pointer-events: none;
        justify-content: center;
        align-self: center;
        align-items: center;
    }
    .carousel-image{
        flex:1;
        height: 100%;
        width: auto;
        margin: auto;
        pointer-events: none;
        justify-content: center;
        align-self: center;
    }
    .carousel-one-image-overlay{
        pointer-events: none;
        justify-content: center;
        overflow: hidden;
        margin: auto;
        width: auto;
        height: 60vh;
        top: 0;
        left: 0;
        align-self: center;
    }
    .carousel-one-title{
        position: relative;
        font-size: 24px;
        font-style:bold;
        padding-bottom: 2%;
        padding-top: 2%;
        width:100%;
        color: white;
    }
    .service-info{
        width: 30vw;
    }
    .service-image{
        width: 60vw;
    }
    .service-title{
        font-family: 'Cera pro','Trebuchet MS',sans-serif;
        font-size: 2vw;
        text-align: left;
        padding-bottom: 2vh;
    }
    .service-details{
        font-family: 'Cera pro','Trebuchet MS',sans-serif;
        font-size: 1vw;
        color: #e4e4e4;
    }
    .team-title{
        padding-top: 20px;
    }
    .team-image{
        height: 100%;
    }
    .team-carousel{
        padding-top: 20px;
    }
    .company-image{
        width:10vw;
        height:10vw;
    }
    .company-details{
        text-align: justify;
        width: auto;
        margin: auto;
        font-size: 1vw;
        color: #e4e4e4;
    }
    .card{
        flex:1;
        margin-left: 10vw;
        margin-right: 10vw;
        background-color: rgb(15, 15, 15);
        color: rgb(170, 170, 170);
        height:auto;
        width: auto;
        padding-top: 10vh;
        padding-bottom: 10vh;
        align-items: center;
    }
    .team-card{
        margin-left: 10vw;
        margin-right: 10vw;
        background-color: rgb(15, 15, 15);
        color: rgb(170, 170, 170);
        height:auto;
        width: auto;
        padding-left: 10vw;
        padding-right: 10vw;
    }
    .screen{
        height: auto;
        padding-bottom: 5vh;
        width: 100vw;
        font-family: "Open Sans", sans-serif;
        margin: auto;
    }
/* Breakpoint for smaller screens */
@media screen and (max-width: 1400px) {
    .nav-title{
        font-size: 44px;
        font-family: 'Cera pro','Trebuchet MS',sans-serif;
    }
    .nav-menu{
        position: fixed;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(5px);
        height: 90px;
        font-weight: 600;
    }
    .navbar-brand{
        height: 90px;
    }
    .navbar-menu{
        background-color: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(5px);
    }
    .navbar-end {
        justify-content: flex-end;
        margin-left: auto;
        flex: 1;
    }
    .nav-line{
        position: absolute;
        margin:auto;
        bottom: 0cm;
        height:3px;
        width:100%;
        background-color: #ff39c0;
    }
    .scale{
        transform: scale(1.8);
    }
    .company-name{
        font-size: 5vw;
        margin-top: 10px;
        text-align: center;
    }
    .quote{
        font-size: 1.5rem;
        font-family:'Lucida Sans', Arial, sans-serif;
        font-style: italic;position: relative;bottom: 25px;left: 20px;
    }
    .front-page{
        height: 100vh;
        width: 100vw;
        /* font-family:  "Old Standard TT", sans-serif; */
        font-family:  "Gentium Basic", sans-serif;
    }
    .text-header{
        font-family: 'Cera pro','Trebuchet MS',sans-serif;
    }
    .text-body{
        font-family: "Open Sans", sans-serif;
        text-align: justify;
        line-height: 2;
        padding-top: 2vh;
    }
    .punch-line{
        padding-top: 15vh;
    }
    .logo{
        width:5vw;
    }
    .title{
        font-size: 4vw;
        font-family: 'Cera pro','Trebuchet MS',sans-serif;
    }
    .carousel-one-container{
        flex:1;
        position: relative;
        width: 100vw;
        height:60vh;
        justify-content: center;
        align-items: center;
    }
    .carousel-one{
        flex:inherit;
        max-width: 100%;
        height: 100%;
        margin: auto;
        justify-content: center;
        align-items: center;
    }
    .carousel-one-overlay{
        flex:1;
        position: absolute;
        color: rgb(201, 201, 201);
        margin:auto;
        top:0;
        left:0;
        right:0;
        bottom:0;
        align-items: center;
        align-content: center;
        width: auto;
        height: 100%;
        margin-left: 5%;
        margin-right: 5%;
    }
    .carousel-one-image{
        flex:1;
        height: 60vh;
        margin:auto;
        width: auto;
        pointer-events: none;
        justify-content: center;
        align-self: center;
        align-items: center;
    }
    .carousel-image{
        flex:1;
        height: 100%;
        width: auto;
        margin: auto;
        pointer-events: none;
        justify-content: center;
        align-self: center;
    }
    .carousel-one-image-overlay{
        pointer-events: none;
        justify-content: center;
        overflow: hidden;
        margin: auto;
        width: auto;
        height: 60vh;
        top: 0;
        left: 0;
        align-self: center;
    }
    .carousel-one-title{
        position: relative;
        font-size: 24px;
        font-style:bold;
        padding-bottom: 2%;
        padding-top: 2%;
        width:100%;
        color: white;
    }
    .service-info{
        width: 30vw;
    }
    .service-image{
        width: 60vw;
    }
    .service-title{
        font-family: 'Cera pro','Trebuchet MS',sans-serif;
        font-size: 2vw;
        text-align: left;
        padding-bottom: 2vh;
    }
    .service-details{
        font-family: 'Cera pro','Trebuchet MS',sans-serif;
        font-size: 1.5vw;
        color: #e4e4e4;
    }
    .team-title{
        padding-top: 20px;
    }
    .team-image{
        height: 100%;
    }
    .team-carousel{
        padding-top: 20px;
    }
    .company-image{
        width:15vw;
        height:15vw;
    }
    .company-details{
        text-align: justify;
        width: auto;
        margin: auto;
        font-size: 1.5vw;
        color: #e4e4e4;
    }
    .card{
        flex:1;
        margin-left: 7vw;
        margin-right: 7vw;
        background-color: rgb(15, 15, 15);
        color: rgb(170, 170, 170);
        height:auto;
        width: auto;
        padding-top: 5vh;
        padding-bottom: 5vh;
        align-items: center;
    }
    .team-card{
        margin-left: 10vw;
        margin-right: 10vw;
        background-color: rgb(15, 15, 15);
        color: rgb(170, 170, 170);
        height:auto;
        width: auto;
        padding-left: 5vw;
        padding-right: 5vw;
    }
    .screen{
        height: auto;
        padding-bottom: 5vh;
        width: 100vw;
        font-family: "Open Sans", sans-serif;
    }
}

/* Breakpoint for mobile phone screens */
@media screen and (max-width: 768px) {
    .nav-title{
        font-size: 6vw;
        font-family: 'Cera pro','Trebuchet MS',sans-serif;
        margin:auto;
        margin:auto;
    }
    .nav-menu{
        position: fixed;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(5px);
        height: 90px;
        font-weight: 600;
        align-items: center;
    }
    .navbar-brand{
        height: 90px;
    }
    .navbar-menu{
        background-color: rgba(0, 0, 0, 0.8);
        backdrop-filter: blur(5px);
    }
    .nav-line{
        position: absolute;
        margin:auto;
        bottom: 0cm;
        height:3px;
        width:100%;
        background-color: #ff39c0;
    }
    .scale{
        transform: scale(1.8);
    }
    .company-name{
        font-size: 5vw;
        margin-top: 10px;
        text-align: center;
    }
    .quote{
        font-size: 1.5rem;
        font-family:'Lucida Sans', Arial, sans-serif;
        font-style: italic;position: relative;bottom: 25px;left: 20px;
    }
    .front-page{
        height: 100vh;
        width: 100vw;
        /* font-family:  "Old Standard TT", sans-serif; */
        font-family:  "Gentium Basic", sans-serif;
    }
    .text-header{
        font-family: 'Cera pro','Trebuchet MS',sans-serif;
    }
    .text-body{
        font-family: "Open Sans", sans-serif;
        text-align: justify;
        line-height: 2;
        padding-top: 2vh;
    }
    .punch-line{
        padding-top: 15vh;
    }
    .logo{
        width:5vw;
    }
    .title{
        font-size: 4vw;
        font-family: 'Cera pro','Trebuchet MS',sans-serif;
        margin:auto;
    }
    .carousel-one-container{
        flex:1;
        position: relative;
        width: 100vw;
        height:60vh;
        justify-content: center;
        align-items: center;
    }
    .carousel-one{
        flex:inherit;
        max-width: 100%;
        height: 100%;
        margin: auto;
        justify-content: center;
        align-items: center;
    }
    .carousel-one-overlay{
        flex:1;
        position: absolute;
        color: rgb(201, 201, 201);
        margin:auto;
        top:0;
        left:0;
        right:0;
        bottom:0;
        align-items: center;
        align-content: center;
        width: auto;
        height: 100%;
        margin-left: 5%;
        margin-right: 5%;
    }
    .carousel-one-image{
        flex:1;
        height: 60vh;
        margin:auto;
        width: auto;
        pointer-events: none;
        justify-content: center;
        align-self: center;
        align-items: center;
    }
    .carousel-image{
        flex:1;
        height: 100%;
        width: auto;
        margin: auto;
        pointer-events: none;
        justify-content: center;
        align-self: center;
    }
    .carousel-one-image-overlay{
        pointer-events: none;
        justify-content: center;
        overflow: hidden;
        margin: auto;
        width: auto;
        height: 60vh;
        top: 0;
        left: 0;
        align-self: center;
    }
    .carousel-one-title{
        position: relative;
        font-size: 24px;
        font-style:bold;
        padding-bottom: 2%;
        padding-top: 2%;
        width:100%;
        color: white;
    }
    .service-info{
        width: 50vw;
    }
    .service-image{
        width: auto;
        margin:auto;
        border-radius: 5%;
    }
    .service-title{
        font-family: 'Cera pro','Trebuchet MS',sans-serif;
        font-size: 3vw;
        text-align: left;
        width: auto;
        margin:auto;
        padding-bottom: 2vh;
    }
    .service-details{
        font-family: 'Cera pro','Trebuchet MS',sans-serif;
        font-size: 2.5vw;
        width: 80vw;
        width: auto;
        margin:auto;
        color: #e4e4e4;
    }
    .team-title{
        padding-top: 2vh;
    }
    .team-image{
        height: 100%;
    }
    .team-carousel{
        padding-top: 20px;
    }
    .company-image{
        width:25vw;
        height:25vw;
    }
    .company-details{
        text-align: justify;
        width: auto;
        margin: auto;
        font-size: 2.5vw;
        color: #e4e4e4;
    }
    .card{
        flex:1;
        margin-left: 5vw;
        margin-right: 5vw;
        background-color: rgb(15, 15, 15);
        color: rgb(170, 170, 170);
        height:auto;
        width: auto;
        padding-bottom: 5vh;
        align-items: center;
    }
    .team-card{
        margin-left: 10vw;
        margin-right: 10vw;
        background-color: rgb(15, 15, 15);
        color: rgb(170, 170, 170);
        height:auto;
        width: auto;
        padding-left: 5vw;
        padding-right: 5vw;
    }
    .screen{
        height: auto;
        padding-bottom: 5vh;
        width: 100vw;
    }
}
    .primary-background{
        background-color: #ff39c0;
        color: white;
        border-color: #ff39c0;
    }
    .primary-color{
        color: #ff39c0;
    }
    .secondary-background{
        background-color: #8c10562f;
        color: white;
        padding: auto;
    }
    .tertiary-background{
        /* background-color: rgb(27, 4, 20); */
        color: white;
    }
    .footer-background{
        background-color: #8c10562f;
    }
    .white{
        color: white;
    }
    .whitesmoke{
        color: whitesmoke;
    }
    .round{
        border-radius: 30px;
    }
    .noShadow{
        box-shadow: none;
    }
    #mv_left{
        margin-left: 10px;
    }
    .button{
        margin-bottom: 10px;
    }
    .round-border{
        border-radius: 20px;
        background-color: rgb(15, 15, 15);
    }
    .button-border{
        background-color: rgb(15, 15, 15);
        border-color: rgb(15, 15, 15);
        color: white;
    }
    .button-border:hover{
        background-color: #ff39c0;
        border-color: #ff39c0;
        color: white;
    }
    .has-padding{
        padding: 5px;
    }
    .outline {
        /* font-family: 'Nunito', sans-serif; */
        -moz-text-fill-color:rgb(238, 0, 163);
        -webkit-text-fill-color:rgb(238, 0, 163);
        -moz-text-stroke-color:rgb(238, 0, 163);
        -webkit-text-stroke-color:rgb(238, 0, 163);
        -moz-text-stroke-width: 2px;  
        -webkit-text-stroke-width: 2px;
        /* font-size: 50px; */
        /* margin-top: 20vh; */
    }
    /* ---------------------------Animation------------------------ */
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .temp{
        transition: transform 400ms;
        opacity: 0;
        visibility: hidden;
    }.temp.is-active{
        transition: opacity 400ms, visibility 400ms;
        opacity: 1;
        visibility: visible;
    }
    .loading-modal{
        transition: opacity 400ms;
        opacity: 0;
        /* z-index: -999; */
    }
    .loading-modal.active{
        transition: opacity 400ms;
        opacity: 1;
        /* z-index: 1; */
    }
</style>